import React, {useState, useEffect} from "react"
import Layout from "src/components/core/layout/layout"
import { graphql } from "gatsby"
import TagsWrapper from "src/components/tags/tags-wrapper"
import PostsIndex from "src/components/posts/posts-index"
import Tag from "src/components/tags/tag"
import { Container, Row, Col } from "react-grid-system"
import styled from "styled-components"
import colors from "src/components/core/theme/colors"
import GatsbyLink from "gatsby-link"
import Img from 'gatsby-image';

const Title = styled.h2`
  font-size: 1.5rem;
  color: ${colors.darkGray};
  font-family: "EB Garamond", "serif";
  font-family: "Crimson Text", "serif";font-family: 'Halant', serif;
  font-weight: normal;
  margin-top: 0.5rem;
`

const BooksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  @media (min-width: 576px) {
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0 -0.2rem;
  }
  @media (min-width: 1200px) {
    flex-wrap: nowrap;
    margin: 0 -0.5rem;
  }
`

const BookWrapper = styled.div`
  flex: 1 1 100%;
  margin-bottom: 1.5rem;
  @media (min-width: 576px) {
    flex: 1 1 50%;
    padding: 0 0.2rem;
  margin-bottom: 0;
  }
  @media (min-width: 1200px) {
    flex: 0 1 32%;
    padding: 0 0.5rem;
    margin-bottom: 0;
  }
`

export const query = graphql`
  query HomeQuery {
      

    allPrismicBook(sort: {order: ASC, fields: data___order}) {
      nodes {
        uid
        data {
          title {
            text
          }
          bookcover {
            localFile{
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
          }
          cover {
            localFile {
              childImageSharp {
                fluid {
                  base64
                  tracedSVG
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
          abstract {
            html
          }
        }
      }
    }
    allPrismicTag {
      nodes {
        data {
          tag
        }
        prismicId
      }
    }
  }
`

const Home = ({ data, search }) => {
  const tags = data.allPrismicTag.nodes
  const books = data.allPrismicBook.nodes
  const [loaded, setLoaded] = useState(false)
  useEffect(()=>setLoaded(true), [])
return !loaded ? <Layout></Layout> : (
    <Layout sidebar={true} toptags={false} portrait={true}>
      <TagsWrapper>
        {tags.map(tag => (
          <Tag tag={tag.data.tag ? tag.data.tag : null}>{tag.data.tag ? tag.data.tag : null}</Tag>
        ))}
      </TagsWrapper>
      <BooksWrapper>
        {books.map(book => {
          return(<BookWrapper>
            <a href={"book/" + book.uid}>
              <Img fluid={book.data.bookcover && book.data.bookcover.localFile !== null ? book.data.bookcover.localFile.childImageSharp.fluid : null} />
            </a>
          </BookWrapper>)
        })}
      </BooksWrapper>
    </Layout>
  )
}

export default Home
